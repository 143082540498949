import * as React from 'react';
import {
    Card, TextField, CardContent, Box, IconButton, Grid, 
    Typography, Popover, Slider, useTheme, Skeleton, Button, Stack
  } from '@mui/material'

import PercentageCard from './PercentageCard';
import {disp_rank, disp_rank_short} from 'assets/utils';
import Donut from 'components/Donut';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import StarIcon from '@mui/icons-material/Star';

import { Bar } from 'react-chartjs-2';
import { Adsense } from '@ctrl/react-adsense';
import { useAddFavorite, indexOf, useFavorites, useRemoveFavorite } from 'assets/favorites';
import { useLocation, useSearchParams } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SeasonBar from 'components/SeasonBar';
import { next_rank, prev_rank } from 'assets/utils';
import { RankLabel } from 'components/PercentageBar2';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Statistics from './Statistics';

function getRankingNr(data) {
    if (data["leaderboard"] && data["singles"]["current_rank"] >= 95) {
        for (var i = 0; i<data["leaderboard"].length; ++i) {
            let record = data["leaderboard"][i]
            if (record["leaderboard"] == "singles_rm" && data["singles"]["sub_category"] == "M") {
                return record["rank"]
            } else if (record["leaderboard"] == "singles_rv" && data["singles"]["sub_category"] == "V") {
                return record["rank"]
            }
        }
    }

    return null;
}


export default ({data, sx, canSelectSeason}) => {

    const isLoading = data === null;

    return <>

        <Box position="relative" sx={{mt: '25px'}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + 0px))',
                width: "100%", 
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row"
                }}>

            <Box>
            <Typography variant="h6" fontWeight="bold" color="primary" component="div">
            {isLoading ? <Skeleton width="50%"/> : data["name"]}
            </Typography>
            <Typography variant="body2" component="div" color="text.primary" fontWeight="light" sx={{pb: 0.5}}>
            {isLoading ? <Skeleton width="50%"/> : data["club"]}
            </Typography>
            </Box>
            
            {isLoading ? null: <FavoriteButton userId={data["user_id"]} name={data["name"]} club={data["club"]}/>}

            </Card>

            </Box>  

        {!isLoading? <OtherData data={data} canSelectSeason={canSelectSeason}/> : null}
        
       
            {/*<RoundChart/>*/}
    </>
}

function FavoriteButton({userId, name, club}) {

    const favorites = useFavorites();

    const [favorite, setFavorite] = React.useState(indexOf(favorites, userId) >= 0);
    const addFavorite = useAddFavorite();
    const removeFavorite = useRemoveFavorite();

    React.useEffect(() => {
        setFavorite(indexOf(favorites, userId) >= 0)
    }, [userId])

    const toggleFavorite = () => {
        setFavorite(!favorite)
        if (favorite) {
            removeFavorite(userId)
        } else {
            addFavorite({userId: userId, name: name, club: club})
        }
        
    }

    return <IconButton display="flex" onClick={toggleFavorite}>
        {favorite ? <StarIcon color="primary" fontSize='medium'/> : <StarOutlineIcon color="primary" fontSize='medium'/>}
    </IconButton>
}

function OtherData({data,canSelectSeason}) {
    const single=data["singles"]["current_rank"]
    const single_pred=data["singles"]["predicted_rank"]
    const single_sub=data["singles"]["sub_category"]

    const double=data["doubles"]["current_rank"]
    const double_pred=data["doubles"]["predicted_rank"]
    const double_sub=data["doubles"]["sub_category"]

    const rankingnr = getRankingNr(data);

    const minRank = single_sub.length > 1 ? 1 : 3;
    const maxRank = single_sub.length > 1 ? 30 : 115;

    const firstMark = Math.max(minRank, prev_rank(single, single_sub.length == 1));
    const lastMark = Math.min(Math.max(next_rank(next_rank(next_rank(single))), next_rank(single_pred), double, next_rank(double_pred)), maxRank);

    const theme = useTheme();

    return <> 

    <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div" pt={3}>
        Overzicht
      </Typography>

    {/*<Typography variant="body" component="div" color="text.primary_bold">
            Huidig enkelklassement: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">{disp_rank(single, single_sub)} {rankingnr ? "nr. " + rankingnr : ""}</Typography>
        </Typography>

        <Typography variant="body" component="div" color="text.primary_bold">
            Huidig dubbelklassement: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">{disp_rank(double, double_sub)}</Typography>
        </Typography>*/}

        <Box sx={{marginTop: '30px', marginBottom: '30px'}}>
            <Adsense
              client="ca-pub-9305958838449958"
              slot="4567492781"
              style={{ display: 'block'}}
              layoutKey='-e5+6h-32-8b+ql'
              layout="in-article"
              format="fluid"
            />
            </Box>
        
        <Box display="flex" justifyContent="center">
        <Box sx={{maxWidth: "350px", alignSelf: "center", flexGrow: 1}}>
        <SeasonSelector canSelectSeason={canSelectSeason}/>
        </Box>
        </Box>


        <Card sx={{mt: 1, borderRadius: 2, border: (theme) => '1px solid ' + theme.palette.primary.main}} elevation={0}>
            <Box sx={{pl: 2, backgroundColor: "primary.main", height: 60, display: "flex"}} flexDirection="column" justifyContent="center">
                <Stack flexDirection="row" alignItems="center">
                    <Typography variant="body" color="common.white" sx={{fontWeight: "bold", mr: 1}}>
                        Voorspelling enkel: 
                    </Typography>
                    
                    <RankLabel text={disp_rank_short(single, single_sub)} color={theme.palette.primary.primary} stroke="white" />
                    {single_pred != single ? [<TrendingFlatIcon style={{color: "white"}}/>,
                    <RankLabel text={disp_rank_short(single_pred, single_sub)} color={theme.palette.primary.primary} stroke="white" />] : null}
                </Stack>
                <Typography variant="caption" color="common.white" sx={{fontWeight: "regular", mb: 0.5}}>
                    Huidige klassementscore: {data ? data["singles"]["score"] : ""}
                </Typography>
            </Box>
            <Box sx={{display: "flex", justifyContent: "center"}}>
            <PercentageCard data={data["singles"]} firstMark={firstMark} lastMark={lastMark}/>
            </Box>
        </Card>

        <Card sx={{mt: 2, borderRadius: 2, border: (theme) => '1px solid ' + theme.palette.primary.main}} elevation={0}>
            <Box sx={{pl: 2, backgroundColor: "primary.main", height: 60, display: "flex"}} flexDirection="column" justifyContent="center">
                <Stack flexDirection="row" alignItems="center">
                    <Typography variant="body" color="common.white" sx={{fontWeight: "bold", mr: 1}}>
                        Voorspelling dubbel: 
                    </Typography>
                    
                    <RankLabel text={disp_rank_short(double, double_sub)} color={theme.palette.primary.primary} stroke="white" />
                    {double_pred != double ? [<TrendingFlatIcon style={{color: "white"}}/>,
                    <RankLabel text={disp_rank_short(double_pred, double_sub)} color={theme.palette.primary.primary} stroke="white" />] : null}
                </Stack>
                <Typography variant="caption" color="common.white" sx={{fontWeight: "regular", mb: 0.5}}>
                    Huidige klassementscore: {data ? data["doubles"]["score"] : ""}
                </Typography>
            </Box>
            <Box sx={{display: "flex", justifyContent: "center"}}>
            <PercentageCard data={data["doubles"]} firstMark={firstMark} lastMark={lastMark}/>
            </Box>
        </Card>

        <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div" mt={6}>
        Statistieken
        </Typography>

        {/*<Grid container>
            <Grid item xs={12} sm={6} sx={{display: "flex", alignContent: "center", justifyContent: "center", pt: 2}}>
                <Donut won={data["singles"]["nw"]} lost={data["singles"]["nm"] - data["singles"]["nw"]} name="Enkel"/>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: "flex", alignContent: "center", justifyContent: "center", pt: 2}}>
                <Donut won={data["doubles"]["nw"]} lost={data["doubles"]["nm"] - data["doubles"]["nw"]} name="Dubbel"/>
            </Grid>
        </Grid> */}

        <Statistics data={data}/>

        {data.history.length > 0 ? <>
        <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div" mt={6}>
        Klassementen
        </Typography>
        <PreviousRankingsChart history={data["history"]}/>
        </> : null}
    </>
}

function RoundChart() {
    const theme = useTheme()
    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "bottom"
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
              maxRotation: 0,
            display: false
          },
          x: {
            grid: {
              display: false,
            },
            //ticks: {
            //  maxRotation: 0
            //}
          }
        },
      };
      
    const chartData = {
        labels: ["1/8e finale", "1/4e finale", "1/2e finale", "finale", "finalewinst"],
        datasets: [
        {
            label: "Enkel",
            data: [30, 20, 10, 1],
            backgroundColor: theme.palette.primary.main,
            borderRadius: 5,
        },
        {
            label: "Dubbel",
            data: [30, 20, 10, 1],
            backgroundColor: '#d8e7f2',
            borderRadius: 5,
        },
        ],
    
    };
          
    return <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", pt: 2}}>
        <Bar options={options} data={chartData} />
    </Box>;
}

function SeasonSelector({canSelectSeason}) {
    const seasons = [
        {tag: "dec2022", label: "Winterberekening 2023", start: 2022.5},
        {tag: "jun2023", label: "Zomerberekening 2023", start: 2023},
        {tag: "oct2023", label: "Winterberekening 2024", start: 2023.5},
        {tag: "may2024", label: "Zomerberekening 2024", start: 2024},
    ]
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = React.useState(seasons.length - 1);

    React.useEffect(() => {
        const s = searchParams.get("s")

        if (s) {
            var ind = seasons.length - 1;
            for (var i = 0; i < seasons.length; ++i) {
                if (seasons[i].tag == s) {
                    ind = i;
                    break
                }
            }
            setSelected(ind)
        } else {
            setSelected(seasons.length - 1)
        }
    }, [searchParams])

    const select = (i) => {
        if (canSelectSeason()) {
            const newIndex = Math.min(Math.max(i, 0), seasons.length - 1);
            setSearchParams({s: seasons[newIndex].tag})
        }
        
    }

    const marksFrom = 2022.5;
    const marksTo = 2025;
    
    const marks = [
        {value: 2023, label: "2023"},
        {value: 2023.5, label: null},
        {value: 2024, label: "2024"},
        {value: 2024.5, label: null},
    ]

    return [
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>

        <Button
            size="small"
            onClick={() => select(selected - 1)}
            style={{visibility: selected == 0 ? 'hidden' : 'visible'}}
          >
            <KeyboardArrowLeft /> 
          </Button>

        <Typography sx={{fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", userSelect: 'none'}} color="primary.main">
            {seasons[selected].label}
        </Typography>

        <Button
            size="small"
            onClick={() => select(selected + 1)}
            //disabled={selected == seasons.length - 1}
            style={{visibility: selected == seasons.length - 1 ? 'hidden' : 'visible'}}
          >
            <KeyboardArrowRight />
          </Button>
    </Box>,
    <SeasonBar marks={marks} from={marksFrom} to={marksTo} selectedFrom={seasons[selected].start} selectedTo={seasons[selected].start+1}/>]
}

function PreviousRankingsChart({history}) {
    const theme = useTheme()
    const options = {
        responsive: true,
        aspectRatio: 1.5,
        plugins: {
          legend: {
            display: true,
            position: "bottom"
          },
          datalabels: {
            color: '#FFFFFF',
            align: 'end',
            anchor: 'start',
            font: {
                weight: 'bold'
            },
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
              maxRotation: 0,
            display: true
          },
          x: {
            grid: {
              display: false,
            },
            //ticks: {
            //  maxRotation: 0
            //}
          }
        },
      };
      
    const chartData = {
        labels: history.map(el => el["l"]),
        datasets: [
        {
            label: "Enkel",
            data: history.map(el => el["s"]),
            backgroundColor: history.map(el => el["p"] ? '#96cefa' : theme.palette.primary.main),
            borderRadius: 5,
        },
        {
            label: "Dubbel",
            data: history.map(el => el["d"]),
            backgroundColor: history.map(el => el["p"] ? '#6d95bf' : theme.palette.primary.dark),
            borderRadius: 5,
        },
        ],
    
    };
          
    return <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", pt: 2}}>
        <Bar options={options} data={chartData} plugins={[ChartDataLabels]} />
    </Box>;
}